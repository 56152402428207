import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { getCloudinaryPathOnly } from "../utils/transformations";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SubSenseCollectionScript from "../components/SubSenseCollectionScript";

// eslint-disable-next-line
export const GuideTemplate = ({
  content,
  contentComponent,
  leadtext,
  title,
  subheader,
  contentListHeader,
  contentListItems,
  buttonText,
  formPostUrl,
  descriptionHeader,
  featuredImage,
  featuredImageAlt,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const leadMagnetFormDivRef = useRef(null)
  const executeScroll = () => leadMagnetFormDivRef.current.scrollIntoView({ behavior: 'smooth' })    

  return (
    <>
      {helmet || ""}
      <div className="md:hidden pt-10 bg-gray-100">
        <PreviewCompatibleImage
          imageInfo={{
            image: `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,g_auto,q_auto,h_300/${featuredImage}`,
            alt: featuredImageAlt,
          }}
          className="m-auto"
        />
      </div>
      <div className="md:min-h-full flex items-center justify-center bg-gray-100">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96" ref={leadMagnetFormDivRef}>
            <div>
              <h3 className="uppercase">{subheader}</h3>
              <h1 className="mt-15 text-3xl font-extrabold text-gray-900">{title}</h1>
            </div>
            <p className="my-5">{leadtext}</p>
            <div className="mt-8">
              <div className="mt-6">
                <form action={formPostUrl} method="POST" className="space-y-6">
                  <div className="">
                    <label htmlFor="cta-email" className="sr-only">Email address</label>
                    <input id="cta-email" name="email_address" type="email" className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" placeholder="Enter your email" required />
                  </div>
                  <div className="">
                    <label htmlFor="esp" className="sr-only">Email Marketing Provider</label>
                    <select name="fields[esp]" id="esp" className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300" required>
                      <option value="">Your Email Marketing Provider</option>
                      <option value="convertkit">ConvertKit</option>
                      <option value="mailchimp">MailChimp</option>
                      <option value="sendinblue">Sendinblue</option>
                      <option value="drip">Drip</option>
                      <option value="hubspot">Hubspot</option>
                      <option value="mailerlite">MailerLite</option> 
                      <option value="aweber">AWeber</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div>
                    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{buttonText}</button>
                  </div>
                  <div>
                    <p className="text-sm">By submitting this form you agree to receive news and offers from 
                      Subscribe Sense. We never sell or share your data, in accordance with our <Link to="/privacy" className="font-bold underline">privacy policy</Link>.
                      You can unsubscribe from our updates at any time. 
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block md:max-w-sm lg:max-w-md items-center m-10">
          <PreviewCompatibleImage
            imageInfo={{
              cldImageName: featuredImage,
              // image: `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,g_auto,q_auto,h_550/${featuredImage}`,
              alt: featuredImageAlt,
            }}
          />
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-5xl mx-auto py-16 px-8 md:px-8 sm:px-6">
          <div>
            <h2 className="my-8 text-3xl font-extrabold text-gray-900">{contentListHeader}</h2>
          </div>
          <div className="md:mt-12 xs:mx-4 mx-12 lg:mt-0">
            <dl className="space-y-10">
              {!!contentListItems && contentListItems.map((item, index) => (
                <div className="" key={index}>
                  <dt>
                    {/* Heroicon name: outline/check */}
                    <svg className="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">{item}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-5xl mx-auto py-8 px-8 md:px-8 sm:px-6 md:grid md:grid-cols-3 gap-4">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">{descriptionHeader}</h2>
            <hr className="border-4 border-indigo-600"/>
          </div>
          <div className="md:col-span-2 prose text-base text-gray-900 md:-mt-4">
            <PostContent content={content} />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="md:max-w-5xl py-8 md:px-8 md:mx-auto mx-4">
          <button 
            onClick={executeScroll}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xl font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{buttonText}</button> 
        </div>
      </div>
    </>
  )
};

GuideTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  leadtext: PropTypes.string,
  title: PropTypes.string,
  subheader: PropTypes.string,
  buttonText: PropTypes.string,
  formPostUrl: PropTypes.string,
  contentListHeader: PropTypes.string,
  contentListItems: PropTypes.array,
  descriptionHeader: PropTypes.string,
  featuredImage: PropTypes.string,
  featuredImageAlt: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const featuredImage = post.frontmatter.featuredimage ? getCloudinaryPathOnly(post.frontmatter.featuredimage) : undefined;
  const featuredImageAlt = post.frontmatter.featuredimagealt;
  return (
    <Layout location={location} wrapperClasses="" footer_children={<SubSenseCollectionScript/>}>
      <GuideTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Subscribe Sense Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="keywords"
              content={post.frontmatter.metakeywords}
            />

            <meta
              property="twitter:title"
              content={`${post.frontmatter.title}`}
            />
            <meta
              property="twitter:description"
              content={post.frontmatter.description}
            />
            {featuredImage &&
              <meta 
                property="twitter:image"
                content={`https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,q_auto,g_auto,w_1200,h_630/${featuredImage}`}
              />}
            {featuredImageAlt && 
              <meta
                property="twitter:image:alt"
                content={featuredImageAlt}
              />}
            {featuredImage ? 
              <meta 
                property="twitter:card"
                content="summary_large_image"
              /> 
            :
              <meta
                property="twitter:card"
                content="summary"
              />
            }
            <meta
              property="og:title"
              content={post.frontmatter.title}
            />
            <meta
              property="og:description"
              content={post.frontmatter.description}
            />
            <meta
              property="og:type"
              content="article"
            />
            {featuredImage  &&
              <meta 
                property="og:image"
                content={`https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,q_auto,g_auto,w_1200,h_630/${featuredImage}`}
              />} 
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        subheader={post.frontmatter.subheader}
        leadtext={post.frontmatter.leadtext}
        contentListHeader={post.frontmatter.contentlistheader}
        contentListItems={post.frontmatter.contentlistitems}
        buttonText={post.frontmatter.buttontext}
        formPostUrl={post.frontmatter.formposturl}
        descriptionHeader={post.frontmatter.enddescriptionheader}
        featuredImage={featuredImage}
        featuredImageAlt={featuredImageAlt}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query GuidePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        leadtext
        featuredimage
        featuredimagealt
        subheader
        formposturl
        buttontext
        contentlistheader
        contentlistitems
        enddescriptionheader
        metakeywords
      }
      relatedPosts {
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredimage
          featuredimagealt
        }
      }
    }
  }
`;
